import React from "react";
import MobileContent from "./MobileContent";
import DesktopContent from "./DesktopContent";

const ENTER_KEY = 13;

const RegistrationModal = ({ register }) => {
  const mobileVersion = window.innerWidth < 768 || window.innerHeight < 600;

  return mobileVersion ? <MobileContent register={register} /> : <DesktopContent register={register} />;
};

export default RegistrationModal;
