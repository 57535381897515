import React, { useEffect, useState, useRef } from "react";
import "./users.scss";
import "../default_styles/tb-colors.css";
import Header from "../header/Header";
import UsersFilterRow from "./sub_components/UsersFilterRow";
import UserForm from "./sub_components/UserForm";
import TagsDialogWindow from "./sub_components/Tags/TagsDialogWindow";
import { useParams } from "react-router-dom";
import NoMatch from "../default_components/NoMatch";
import Api from "../../Api";
import { useTranslation } from "react-i18next";
import UserInfoTooltip from "./sub_components/UserListGenerator/UserInfoTooltip";
import DeleteDialog from "../default_components/DeleteDialog";
import { sortUsers } from "./sub_components/lib/sortUser";
import LoadingComponent from "../default_components/LoadingComponent";
import { WhiteTooltip } from "../default_components/Tooltips";
import { SliceStringWithDots } from "../../lib/StringFormat";
import XLSExport from "../../lib/XLSExport";
import InfoTableColumnHeader from "../projects/sub_components/projects_table/InfoTableColumnHeader";
import CalendarInegrationDialog from "../default_components/teambook_calendar_integrations/CalendarInegrationDialog";
import { TeambookCheckbox } from "../default_components/TeambookCheckbox";
import { useTeambookDataStore, useUsersVisibilityColumnsStore } from "../../stores/users";
import UsersList from "./sub_components/UserListGenerator/UsersList";
import { icons } from "../default_images/IconsList";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { useProfileStore } from "../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../stores/accountStore";
import { useNotificationStore } from "../../stores/notificationStore";
import { useTeambookFilter } from "../../stores/planner";
import filterUsers from "./sub_components/lib/filterUsers";
import { SwitchToPaidPlanBanner } from "../default_components/SwitchToPaidPlanBanner";
import lsKeys from "../default_values/defaultKeys";
import DidYouKnow from "../planner/default_components/DidYouKnow";

const UsersPage = () => {
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);

  const { t } = useTranslation();

  const [emptyFiltered, setEmptyFiltered] = useState(true);
  const [typeOfUsersShown, setTypeOfUsersShown] = useState("Active");
  const [tagsWindowOpened, setTagsWindowOpened] = useState(false);
  const [sortOrder, setSortOrder] = useState(localStorage.getItem(lsKeys.USERS_SORT_ORDER) || "a");
  const [sortField, setSortField] = useState(localStorage.getItem(lsKeys.USERS_SORT_FIELD) || "last_name");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteDialogProps, setDeleteDialogProps] = useState({});
  const [displayingUsers, setDisplayingUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [calendarIntegrationOpen, setCalendarIntegrationOpen] = useState(false);

  const { columnsVisibility } = useUsersVisibilityColumnsStore();
  const { filterValues, setFilterValue, filterType } = useTeambookFilter();

  const [account] = useAccountStore((state) => [state.account], shallow);
  const [profile] = useProfileStore((state) => [state.profile], shallow);
  const [currentUser] = useProfileStore((state) => [state.profile], shallow);

  const didYouKnowRef = useRef({});
  const UserInfoTooltipRef = useRef({});

  const [
    tags,
    setTags,
    departments,
    setDepartments,
    teams,
    setTeams,
    users,
    setUsers,
    chosenUser,
    setChosenUser,
    setTab,
    userFormState,
    setUserFormState,
  ] = useTeambookDataStore(
    (state) => [
      state.tags,
      state.setTags,
      state.departments,
      state.setDepartments,
      state.teams,
      state.setTeams,
      state.users,
      state.setUsers,
      state.chosenUser,
      state.setChosenUser,
      state.setTab,
      state.userFormState,
      state.setUserFormState,
    ],
    shallow
  );

  const isLoaded = !!users && !!teams && !!account && !!currentUser;

  const { id } = useParams();

  useEffect(() => {
    if (isLoaded)
      setDisplayingUsers(
        sortUsers(
          sortOrder,
          sortField,
          filterUsers(
            users.filter(({ active }) => (typeOfUsersShown === "Active" ? active : !active)),
            filterType,
            filterValues,
            teams,
            tags,
            departments,
            t
          ),
          teams,
          departments
        )
      );
  }, [filterValues, users, filterType, typeOfUsersShown, teams, tags]);

  useEffect(() => {
    setFilterValue([]);
  }, [typeOfUsersShown]);

  useEffect(() => {
    localStorage.setItem(lsKeys.USERS_SORT_FIELD, sortField);
    localStorage.setItem(lsKeys.USERS_SORT_ORDER, sortOrder);
  }, [sortField, sortOrder]);

  const selectUser = (user) => {
    if (selectedUsers.some(({ id }) => id === user.id)) {
      setSelectedUsers(selectedUsers.filter(({ id }) => id !== user.id));
    } else {
      setSelectedUsers([...selectedUsers, user]);
    }
  };

  const selectAllUsers = () => {
    if (displayingUsers.length !== selectedUsers.length) {
      setSelectedUsers(displayingUsers);
    } else {
      setSelectedUsers([]);
    }
  };

  useEffect(() => {
    getAllUsers();
    getAllTags("name");
    getAllTeams();

    Api.Departments.all().then((response) => {
      setDepartments(response.data);
    });
  }, []);

  useEffect(() => {
    let filteredUsersArray = [];
    setSelectedUsers([]);

    if (typeOfUsersShown === "Active") {
      filteredUsersArray = users.filter((item) => item.active === true);
    } else if (typeOfUsersShown === "Deactivated") {
      filteredUsersArray = users.filter((item) => item.active === false);
    }

    if (filteredUsersArray.length > 0) {
      setEmptyFiltered(false);
      setDisplayingUsers(filteredUsersArray);
    } else if (filteredUsersArray.length <= 0) {
      setEmptyFiltered(true);
      setDisplayingUsers(filteredUsersArray);
    }
  }, [typeOfUsersShown, users]);

  useEffect(() => {
    if (filterValues.length > 0 && displayingUsers.length === 0) {
      setEmptyFiltered(true);
    } else {
      setEmptyFiltered(false);
    }
  }, [filterValues, displayingUsers]);

  const getAllUsers = (user) => {
    Api.Users.all().then((response) => {
      if (user) {
        setChosenUser(user);
        setUsers(response.data);
      } else {
        analyzeAllUsersResponse(response);
      }
    });
  };

  const analyzeAllUsersResponse = (response) => {
    setUsers(response.data);
  };

  useEffect(() => {
    sortByField(sortField);
  }, [sortOrder, sortField]);

  const sortByField = (field, order, sortArray = displayingUsers) => {
    setSortField(field);

    if (order) {
      if (sortOrder === "a") {
        setSortOrder("z");
      } else {
        setSortOrder("a");
      }
      setDisplayingUsers(sortUsers(order, field, sortArray, teams, departments));
    } else {
      setDisplayingUsers(sortUsers(sortOrder, field, sortArray, teams, departments));
    }
  };

  const isCurrentUserSelected = () => selectedUsers.map(({ id }) => id).includes(currentUser.id);

  Array.prototype.containsActiveObject = function (result) {
    return !!this.filter(function (el) {
      return el.hasOwnProperty("active") && el.active === result;
    }).length;
  };

  const getAllTags = (sort_by) => {
    Api.Tags.all(sort_by).then((response) => analyzeAllTagsResponse(response));
  };

  const getAllTeams = () => {
    Api.Teams.all().then((response) => {
      setTeams(response.data);
    });
  };

  const analyzeAllTagsResponse = (response) => {
    setTags(response.data);
  };

  const analyzeDeactivateResponse = (response, id) => {
    if (response.status === 200) {
      getAllUsers();
      closeShow();
    } else {
      console.log(response.status);
    }
  };

  const analyzeDeleteUser = (response) => {
    if (response.status === 200) {
      getAllUsers();
      closeShow();
    } else {
      console.log(response.status);
    }
  };

  const openCreation = () => {
    setUserFormState("create");
  };

  const openEditing = () => {
    setUserFormState("edit");
  };

  const closeTagsWindow = () => {
    setTagsWindowOpened(false);
  };

  const openTagsWindow = () => {
    getAllTags("name");
    setTagsWindowOpened(true);
  };

  const showUser = (user) => {
    setChosenUser(user);
    setUserFormState("show");
  };

  const multipleDeactivate = () => {
    setLoading(true);

    Api.Users.deactivate(selectedUsers.map(({ id }) => id)).then(() => {
      setSelectedUsers([]);
      getAllUsers();
      closeShow();
      setLoading(false);
    });
  };

  const deleteUserRequest = (user) => Api.Users.delete([user.id]).then((response) => analyzeDeleteUser(response));

  const deactivateUserRequest = (user) => {
    Api.Users.deactivate([user.id]).then((response) => analyzeDeactivateResponse(response));
  };

  const multipleDelete = () => {
    Api.Users.delete(selectedUsers.map(({ id }) => id)).then(() => {
      setSelectedUsers([]);
      getAllUsers();
      closeShow();
      setLoading(false);
    });
  };

  const activateUser = (user) => {
    if (typeOfUsersShown === "Deactivated") {
      Api.Users.activate(user ? [user.id] : selectedUsers.map(({ id }) => id)).then(() => {
        setSelectedUsers([]);
        closeShow();
        getAllUsers();
      });
    }
  };

  const activateUserFunction = (user) => {
    setDeleteConfirmationOpen(true);
    setDeleteDialogProps({
      deleteRequest: () => activateUser(user),
      buttonText: t("users.reactivate"),
      contentText:
        selectedUsers.length === 1 || user ? (
          <>
            <p>
              {t("users.activate_confirmation_1")}
              <b>{selectedUsers[0]?.name || user?.name || ""}</b>
              {t("users.activate_confirmation_2")}
            </p>
          </>
        ) : (
          <>
            <p>
              {t("users.multiple_activate_confirmation_1")}
              <WhiteTooltip
                placement="top"
                title={selectedUsers
                  .slice(0, 2)
                  .map(({ name }) => SliceStringWithDots(name, 16))
                  .toString()}
              >
                <b style={{ cursor: "pointer" }}>
                  {t("users.selected")}
                  {selectedUsers.length}
                </b>
              </WhiteTooltip>
              {t("users.multiple_activate_confirmation_2")}
            </p>
          </>
        ),
    });
  };

  const deleteUserFunction = (user) => {
    const getDialogProps = (deletionObject, deleteRequest, buttonText, contentText) => ({
      deletionObject,
      deleteRequest,
      buttonText,
      contentText,
    });

    const getConfirmationText = (userList, isDeactivation) => {
      const actionText = isDeactivation ? t("users.deactivate_confirmation_1") : t("users.delete_confirmation_1");
      const actionText2 = isDeactivation ? t("users.deactivate_confirmation_2") : t("users.delete_confirmation_2");
      const multipleActionText = isDeactivation
        ? t("users.multiple_deactivate_confirmation_1")
        : t("users.multiple_delete_confirmation_1");
      const multipleActionText2 = isDeactivation
        ? t("users.multiple_deactivate_confirmation_2")
        : t("users.multiple_delete_confirmation_2");

      return userList.length === 1 ? (
        <>
          <p>
            {actionText}
            <b>{userList[0].name}</b>
            {actionText2}
          </p>
        </>
      ) : (
        <>
          <p>
            {multipleActionText}
            <WhiteTooltip
              placement="top"
              title={userList
                .slice(0, 2)
                .map(({ name }) => SliceStringWithDots(name, 16))
                .toString()}
            >
              <b style={{ cursor: "pointer" }}>
                {t("users.selected")} {` ${userList.length}`}
              </b>
            </WhiteTooltip>
            {multipleActionText2}
          </p>
        </>
      );
    };

    const getRequestAndText = (type, user = null) => {
      if (user) {
        return type === "Active"
          ? {
              deleteRequest: deactivateUserRequest,
              buttonText: t("users.deactivate"),
              contentText: getConfirmationText([user], true),
            }
          : {
              deleteRequest: deleteUserRequest,
              buttonText: t("Delete"),
              contentText: getConfirmationText([user], false),
            };
      } else {
        return type === "Active"
          ? {
              deleteRequest: multipleDeactivate,
              buttonText: t("users.deactivate"),
              contentText: getConfirmationText(selectedUsers, true),
            }
          : {
              deleteRequest: multipleDelete,
              buttonText: t("Delete"),
              contentText: getConfirmationText(selectedUsers, false),
            };
      }
    };

    if (user || selectedUsers.length) {
      setDeleteConfirmationOpen(true);
      const { deleteRequest, buttonText, contentText } = getRequestAndText(typeOfUsersShown, user);
      const dialogProps = getDialogProps(user || selectedUsers, deleteRequest, buttonText, contentText);
      setDeleteDialogProps(dialogProps);
    }
  };

  const columnConfig = [
    {
      field: "last_name",
      title: t("users.full_name"),
      width: "column-width-3",
      visible: true,
    },
    {
      field: "email",
      title: t("users.email"),
      width: "column-width-2 mobile_hidden",
      visible: columnsVisibility["email"],
    },
    {
      field: "role",
      title: t("users.authorization"),
      width: "column-width-1",
      visible: columnsVisibility["role"],
    },
    {
      field: "billable",
      title: t("users.billable"),
      width: "column-width-1 mobile_hidden",
      visible: columnsVisibility["billable"],
    },
    {
      field: "department",
      title: t("users.department"),
      width: "column-width-2 mobile_hidden",
      visible: columnsVisibility["department"],
    },
    {
      field: "team_ids",
      title: t("users.teams"),
      width: "column-width-4 mobile_hidden",
      visible: columnsVisibility["teams"],
    },
    {
      field: "tags",
      title: t("users.tags"),
      width: "column-width-4 mobile_hidden",
      visible: columnsVisibility["tags"],
    },
    {
      field: "start_date",
      title: t("users.selected_fields.start_date"),
      width: "column-width-1 mobile_hidden",
      visible: columnsVisibility["start_date"],
    },
    {
      field: "end_date",
      title: t("users.selected_fields.end_date"),
      width: "column-width-1 mobile_hidden",
      visible: columnsVisibility["end_date"],
    },
  ];

  const renderColumnHeader = (column) => {
    if (!column.visible) return null;

    return (
      <InfoTableColumnHeader
        key={column.field}
        sortingField={sortField}
        sortFunction={sortByField}
        sortingOrder={sortOrder}
        setSortOrder={setSortOrder}
        columnTitle={column.title}
        columnField={column.field}
        className={`users-page__user-list-header-value ${column.width}`}
      />
    );
  };

  if (id) {
    let userFromUrl = users.filter((user) => {
      return user.id === +id;
    })[0];

    if (!userFromUrl) {
      return <NoMatch />;
    } else {
      setChosenUser(userFromUrl);
    }
  }

  const closeShow = () => {
    setUserFormState("");
    setChosenUser(null);
    setTab("general");
  };

  const exportUsers = () => {
    XLSExport.ExportUsers(selectedUsers);
    setSelectedUsers([]);
  };

  const copyCalendar = (user) => {
    setCalendarIntegrationOpen(true);
    navigator.clipboard.writeText(Api.Calendar.link((selectedUsers[0] || user)?.calendar_token));
    setInAppNotification(t("notifications.users.copy_calendar"));
  };

  return (
    <div className="users-page">
      <Header didYouKnowRef={didYouKnowRef} page="users" />

      {isLoaded && (
        <>
          <UsersFilterRow
            users={users.filter(({ active }) => (typeOfUsersShown === "Active" ? active : !active))}
            setDeactivatedSelectUsers={setTypeOfUsersShown}
            openCreation={openCreation}
            getAllUsers={getAllUsers}
            openTagsWindow={openTagsWindow}
            sortByField={sortByField}
            sortField={sortField}
            displayingUsers={displayingUsers}
            selectedUsers={selectedUsers}
            deactivatedSelectUsers={typeOfUsersShown}
          />

          <TagsDialogWindow
            closeTagsWindow={closeTagsWindow}
            opened={tagsWindowOpened}
            getAllTags={getAllTags}
            getAllUsers={getAllUsers}
          />

          <UserInfoTooltip ref={UserInfoTooltipRef} />

          <div className="users-page__users-buttons">
            {selectedUsers.length > 0 && (
              <p style={{ margin: "0px 0px 0px 54px", textAlign: "left" }}>
                {selectedUsers.length}&nbsp;
                {t(`users.selected_user${selectedUsers.length > 1 ? "s" : ""}_count`)}
              </p>
            )}

            {selectedUsers.length === 1 && isCurrentUserSelected() && (
              <TeambookIcon
                id="copyCalendarPageButton"
                className="users-page__user-button"
                onClick={copyCalendar}
                alt="Copy"
                name={icons.CALENDAR}
                color="alternative_default"
                tooltipTitle={t("users.copy_calendar")}
              />
            )}

            {selectedUsers.length > 0 && (
              <TeambookIcon
                id="exportUsersPageButton"
                className="users-page__user-button"
                onClick={() => exportUsers()}
                alt="Export"
                color="alternative_default"
                name={icons.EXPORT_ARROW}
                tooltipTitle={t("dashboard.export_data")}
              />
            )}

            {typeOfUsersShown === "Deactivated" && !isCurrentUserSelected() && selectedUsers.length > 0 && (
              <TeambookIcon
                id="deleteUserPageButton"
                className="users-page__user-button"
                onClick={() => deleteUserFunction()}
                alt="Delete"
                color="alternative_default"
                name={icons.TRASH}
                tooltipTitle={t("delete")}
              />
            )}

            {typeOfUsersShown === "Active" && !isCurrentUserSelected() && selectedUsers.length > 0 && (
              <TeambookIcon
                id="deactivateUserPageButton"
                className="users-page__user-button"
                onClick={() => deleteUserFunction()}
                alt="Delete"
                color="alternative_default"
                name={icons.TRASH}
                tooltipTitle={t("users.deactivate")}
              />
            )}

            {typeOfUsersShown === "Deactivated" && selectedUsers.length > 0 && (
              <TeambookIcon
                id="reactivateUserPageButton"
                className="users-page__user-button"
                onClick={() => activateUserFunction()}
                color="alternative_default"
                name={icons.ADD_USER}
                tooltipTitle={t("users.reactivate")}
              />
            )}
          </div>

          <DeleteDialog open={deleteConfirmationOpen} setOpen={setDeleteConfirmationOpen} {...deleteDialogProps} />

          <div className="users-page__users-content">
            {!emptyFiltered && (
              <div className="users-page__user-list-header">
                <div className="user-list__fixed-field">
                  <div id="selectAllUsers" className="user-block__select-checkbox" onClick={selectAllUsers}>
                    <TeambookCheckbox
                      type="checkbox"
                      checked={selectedUsers.length === displayingUsers.length && selectedUsers.length > 0}
                      onChange={selectAllUsers}
                    />
                  </div>

                  {renderColumnHeader(columnConfig[0])}
                </div>

                {columnConfig.slice(1).map(renderColumnHeader)}
              </div>
            )}

            {loading ? (
              <LoadingComponent />
            ) : (
              <UsersList
                users={displayingUsers}
                showUser={showUser}
                emptyFiltered={emptyFiltered}
                selectUser={selectUser}
                selectedUsers={selectedUsers}
              />
            )}
            {selectedUsers.length > 0 && calendarIntegrationOpen && (
              <CalendarInegrationDialog
                open={calendarIntegrationOpen}
                setOpen={setCalendarIntegrationOpen}
                user={selectedUsers[0]}
              />
            )}
            {profile && <DidYouKnow ref={didYouKnowRef} />}
            {((!emptyFiltered && displayingUsers.length > 0 && chosenUser) || userFormState === "create") && (
              <UserForm
                onEdit={openEditing}
                analyzeAllUsersResponse={analyzeAllUsersResponse}
                getAllUsers={getAllUsers}
                setDeactivatedSelectUsers={setTypeOfUsersShown}
                closeShow={closeShow}
                deleteUserFunction={deleteUserFunction}
                activateUserFunction={activateUserFunction}
                deactivatedSelectUsers={typeOfUsersShown}
                copyCalendar={copyCalendar}
              />
            )}
          </div>
        </>
      )}

      {currentUser && <SwitchToPaidPlanBanner />}
    </div>
  );
};

export default UsersPage;
