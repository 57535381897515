import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ApiIntegrationBlock from "./ApiIntegrationBlock";
import SlackBlock from "./SlackBlock";
import HubspotBlock from "./HubspotBlock";
import ZapierBlock from "./ZapierBlock";
import QuickBooksBlock from "./QuickBooksBlock";
import PersonioBlock from "./personio/PersonioBlock";
import Api from "../../../../Api";
import EntraSyncBlock from "./EntraSyncBlock";
import { IntegrationGroup } from "./IntegrationGroup";

const IntegrationComponent = () => {
  const { t } = useTranslation();
  const [qbData, setQbData] = useState();

  let greetingMessage = t("settings.integration.greeting_text").split("__");

  let messageWithoutEmail = greetingMessage[1].split("support@teambookapp.com");

  useEffect(() => {
    Api.Integrations.QuickBooks.get().then((res) => {
      setQbData(res.data);
    });
  }, []);

  let integrationGroups = [
    {
      name: t("settings.integration.connect_to_anything"),
      components: [<ApiIntegrationBlock />, <ZapierBlock />],
    },
    {
      name: t("settings.integration.team_communication"),
      components: [<SlackBlock />],
    },
    {
      name: "CRM",
      components: [<HubspotBlock />],
    },
    {
      name: t("settings.integration.backoffice"),
      components: [<QuickBooksBlock qbData={qbData} />, <PersonioBlock />],
    },
    {
      name: t("settings.integration.corporate_tools"),
      components: [<EntraSyncBlock />],
    },
  ];

  return (
    <div className="account-schedule main-settings-component">
      <div className="greeting">
        <p className="greeting__header"> {greetingMessage[0]}</p>
        <p className="greeting__text">
          {messageWithoutEmail[0]} <a href="mailto:support@teambookapp.com">support@teambookapp.com</a>{" "}
          {messageWithoutEmail[1]}
        </p>
      </div>

      <div className="integrations__integration-list">
        {integrationGroups.map((group) => (
          <IntegrationGroup title={group.name} apps={group.components} />
        ))}
      </div>
    </div>
  );
};

export default IntegrationComponent;
