import React, { useEffect, useState } from "react";
import "../../../default_styles/tb-colors.css";
import { useTranslation } from "react-i18next";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Api from "../../../../Api";
import UsersData from "../lib/UsersData";
import GetStringRole from "../../../../lib/GetStringRole";
import lsKeys from "../../../default_values/defaultKeys";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { useAccountStore } from "../../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { useProfileStore } from "../../../../stores/profileStore";
import { useNotificationStore } from "../../../../stores/notificationStore";
import { useTeambookDataStore } from "../../../../stores/users";

const ShowUser = ({
  onEdit,
  closeShow,
  teams,
  deleteUserFunction,
  activateUserFunction,
  deactivatedSelectUsers,
  copyCalendar,
}) => {
  const [currentUser] = useProfileStore((state) => [state.profile], shallow);
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const [departments, user, tab, setTab, userFormState] = useTeambookDataStore(
    (state) => [state.departments, state.chosenUser, state.tab, state.setTab, state.userFormState],
    shallow
  );

  const { t } = useTranslation();
  const [notificationSetting, setNotificationSetting] = useState(user.notification_setting);
  const [selectedHoliday, setSelectedHoliday] = useState();
  const role = localStorage.getItem(lsKeys.ROLE);

  const isBlockedEditing = user.role === "admin" && role === "planner";

  useEffect(() => {
    Api.Integrations.Holidays.get().then((res) => {
      if (res.data.length > 0) {
        const holiday = res.data.filter((holiday) => holiday.users.map((u) => u.id).includes(user.id))[0];

        if (holiday) {
          setSelectedHoliday(holiday);
        }
      }
    });
  }, []);

  const reinvite = () => {
    Api.Users.reinvite(user.id).then(() => {
      setInAppNotification("User was reinvited");
    });
  };

  return (
    <Dialog
      className="form-show"
      onClose={closeShow}
      open={userFormState === "show"}
      classes={{ paper: "user-form-dialog-paper" }}
    >
      <DialogTitle>
        {tab === "general" ? (
          <p>{t("users.user_info")}</p>
        ) : (
          <p>{`${t("planning.filter.user")}: ${user.first_name} ${user.last_name[0]}`}</p>
        )}
        <TeambookIcon className="form_close" name={icons.CROSS} onClick={closeShow} />
      </DialogTitle>

      <DialogContent>
        <UsersData
          viewType={"show"}
          firstName={user.first_name}
          lastName={user.last_name}
          email={user.email}
          billable={user.billable ? t("users.billable") : t("dashboard.non_billable")}
          role={GetStringRole(user)}
          timeZone={user.time_zone}
          userTeams={teams}
          teams={teams}
          tags={user.tags}
          schedule={user.schedule}
          startDate={user.start_date}
          endDate={user.end_date}
          tab={tab}
          setTab={setTab}
          notificationSetting={notificationSetting}
          setNotificationSetting={setNotificationSetting}
          selectedHoliday={selectedHoliday}
          notes={user.notes}
          departmentId={departments.find((d) => d.id === user.department_id)?.name || "-"}
        />
      </DialogContent>

      <DialogActions style={{ padding: "0px 40px 40px 40px" }}>
        {deactivatedSelectUsers === "Deactivated" && (
          <TeambookIcon
            id="reactivateUserFormButton"
            className="users-page__user-button"
            onClick={() => activateUserFunction(user)}
            color="alternative_default"
            tooltipTitle={t("users.reactivate")}
            name={icons.ADD_USER}
          />
        )}

        {user.invited && (
          <TeambookIcon
            id="reinviteUserFormButton"
            name={icons.EMAIL}
            onClick={() => {
              reinvite();
            }}
            color="darkblue_new"
            tooltipTitle={t("users.reinvite")}
          />
        )}

        {!isBlockedEditing && currentUser.id !== user.id && (
          <TeambookIcon
            id="deleteUserFormButton"
            name={icons.TRASH}
            onClick={() => deleteUserFunction(user)}
            color="darkblue_new"
            tooltipTitle={deactivatedSelectUsers === "Active" ? t("users.deactivate") : t("delete")}
          />
        )}
        {currentUser.id === user.id && (
          <TeambookIcon
            id="copyCalendarPageButton"
            className="users-page__user-button"
            onClick={() => copyCalendar(user)}
            alt="Copy"
            name={icons.CALENDAR}
            tooltipTitle={t("users.copy_calendar")}
            color="darkblue_new"
          />
        )}
        {!isBlockedEditing && (
          <TeambookBlueButton
            text={t("edit")}
            disabled={deactivatedSelectUsers === "Deactivated"}
            onClick={onEdit}
            className="form__edit-button user__edit-button"
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ShowUser;
