import React, { useEffect, useState } from "react";
import NewUser from "./NewUser/NewUser";
import ShowUser from "./ShowUser/ShowUser";
import EditUser from "./EditUser/EditUser";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { useProfileStore } from "../../../stores/profileStore";
import { useTeambookDataStore } from "../../../stores/users";

const UserForm = ({
  onEdit,
  analyzeAllUsersResponse,
  getAllUsers,
  setDeactivatedSelectUsers,
  closeShow,
  deleteUserFunction,
  activateUserFunction,
  deactivatedSelectUsers,
  copyCalendar,
}) => {
  const [teams, user, userFormState] = useTeambookDataStore((state) => [
    state.teams,
    state.chosenUser,
    state.userFormState,
  ]);
  const [userTeams, setUserTeams] = useState([]);

  useEffect(() => {
    if (userFormState !== "create") {
      setUserTeams(
        user.team_ids.map((user_team) => {
          return teams.find((team) => team.id === user_team);
        })
      );
    }
  }, [user]);

  if (userFormState === "create") {
    return (
      <NewUser
        analyzeAllUsersResponse={analyzeAllUsersResponse}
        setDeactivatedSelectUsers={setDeactivatedSelectUsers}
      />
    );
  } else if (userFormState === "show") {
    return (
      <ShowUser
        teams={userTeams}
        onEdit={onEdit}
        closeShow={closeShow}
        deleteUserFunction={deleteUserFunction}
        activateUserFunction={activateUserFunction}
        deactivatedSelectUsers={deactivatedSelectUsers}
        copyCalendar={copyCalendar}
      />
    );
  } else if (userFormState === "edit") {
    return <EditUser getAllUsers={getAllUsers} />;
  }

  return <div />;
};

export default UserForm;
