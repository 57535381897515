import { FullDaysOfTheWeek, numberOfDay } from "../../../../../lib/DateUtils";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useAccountStore } from "../../../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const WeekRow = ({ type, showWeekendLogs = true }) => {
  const { t } = useTranslation();
  const [account] = useAccountStore((state) => [state.account], shallow);

  const className = type === "approval" ? "week-row__header-approval" : "week-row__header";

  return (
    <div className={`week-row ${className}`}>
      <div className="week-row__total week-row__element">
        <p>{t("actuals.total")}</p>
      </div>

      <div className="week-row__days ">
        {FullDaysOfTheWeek(account.first_week_day, showWeekendLogs).map((day, index, arr) => {
          const isToday = numberOfDay(account.first_week_day)[DateTime.now().weekday] === index;
          return (
            <div
              className="week-row__day week-row__element"
              style={{
                width: `${100 / (showWeekendLogs ? 7 : 5)}%`,
                background: isToday ? "var(--stroke)" : "var(--background-1)",
                color: isToday ? "var(--text-1)" : "var(--text-2)",
                borderTopRightRadius: index === arr.length - 1 && 7,
                boxShadow: index === arr.length - 1 && "unset",
                fontWeight: isToday ? 400 : 300,
              }}
            >
              <p className={`${index === arr.length - 1 ? "last" : ""}`}>{day}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WeekRow;
