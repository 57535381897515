import React, { useEffect, useState } from "react";
import TeambookOutlinedButton from "../../../../default_components/TeambookOutlinedButton";
import { useTranslation } from "react-i18next";
import { DialogActions, DialogTitle } from "@mui/material";
import { TeambookIcon } from "../../../../default_images/TeambookIcon";
import { icons } from "../../../../default_images/IconsList";
import DialogContent from "@mui/material/DialogContent";
import TeambookBlueButton from "../../../../default_components/TeambookBlueButton";
import TeambookTextForm from "../../../../default_components/TeambookTextForm";
import Dialog from "@mui/material/Dialog";
import Api from "../../../../../Api";
import { TeambookReactSelect } from "../../../../default_components/TeambookReactSelect";
import BlueArrowDown from "../../../../default_images/darkblue_arrow_down.svg";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../../../stores/accountStore";
import { useNotificationStore } from "../../../../../stores/notificationStore";
import ConnectedPersonio from "./ConnectedPersonio";

const PersonioBlock = () => {
  const { t } = useTranslation();
  const [account, fetchAccount] = useAccountStore((state) => [state.account, state.fetchAccount], shallow);
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);

  const [personioModalOpened, setPersonioModalOpened] = useState(false);
  const [initializing, setInitializing] = useState(false);

  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const initialiseIntegration = () => {
    setInitializing(true);

    Api.Integrations.Personio.init(clientId, clientSecret)
      .then(() => {
        fetchAccount();
        setInitializing(false);
      })
      .catch((e) => {
        setInAppNotification(e.response.data.error);
      });
  };

  return (
    <div className="integration-block">
      <div className="integration-name">
        <TeambookIcon name={icons.PERSONIO_INTEGRATION_ICON} alt="personio logo" />
      </div>

      <div className="integration-status">
        <p className={account.personio_integration ? "active" : "not_active"}>
          {account.personio_integration ? "Active" : "Not connected"}
        </p>
      </div>

      <TeambookOutlinedButton
        onClick={() => setPersonioModalOpened(true)}
        variant="contained"
        text={account.personio_integration ? t("settings.integration.manage") : t("settings.integration.connect")}
        style={{
          width: "171px",
        }}
      />

      <Dialog
        fullWidth={true}
        open={personioModalOpened}
        onClose={() => setPersonioModalOpened(false)}
        aria-labelledby="max-width-dialog-title"
        className="tb-default-dialog personio-dialog"
      >
        <DialogTitle id="add-user-dialog">
          <p>Personio</p>
          <TeambookIcon name={icons.CROSS} onClick={() => setPersonioModalOpened(false)} />
        </DialogTitle>

        {account.personio_integration ? (
          <ConnectedPersonio
            account={account}
            fetchAccount={fetchAccount}
            setPersonioModalOpened={setPersonioModalOpened}
          />
        ) : (
          <>
            <DialogContent style={{ margin: "20px 0px" }}>
              <div style={{ marginBottom: 30 }}>
                <p>
                  {t("settings.integration.personio.insert_api_credentials")} (
                  <a
                    href={
                      "https://support.personio.de/hc/en-us/articles/4404623630993-Generate-and-manage-API-credentials"
                    }
                    target="_blank"
                  >
                    {t("settings.integration.personio.whats_that")}
                  </a>
                  )
                </p>

                <p>
                  Required API access:
                  <ul>
                    <li>User synchronization</li>
                    <ul>
                      <li>Read Users data (Name, Email)</li>
                    </ul>
                    <li>Time Off synchronization</li>
                    <ul>
                      <li>Read Time Off types</li>
                      <li>Read Time Offs (Absences)</li>
                    </ul>
                  </ul>
                </p>

                <TeambookTextForm
                  fieldName={"Client Id"}
                  onChange={setClientId}
                  fieldValue={clientId}
                  type={"text"}
                  width={"400px"}
                  placeholder={"papi-...."}
                />

                <TeambookTextForm
                  fieldName={"Client Secret"}
                  onChange={setClientSecret}
                  fieldValue={clientSecret}
                  type={"text"}
                  width={"400px"}
                  placeholder={"papi-...."}
                />

                <p style={{ marginTop: 25, marginBottom: 0 }}>
                  After submitting the form, you will be asked to configure what to synchronize; only then will the
                  system sync.
                </p>
              </div>
            </DialogContent>

            <DialogActions>
              <TeambookBlueButton
                onClick={initialiseIntegration}
                variant="contained"
                text={t("submit")}
                style={{
                  cursor: initializing ? "wait" : "pointer",
                  position: "absolute",
                  right: 40,
                  bottom: 30,
                }}
              />
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default PersonioBlock;
