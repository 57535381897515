import Menu from "@mui/material/Menu";
import React from "react";

const TeambookMenuComponent = ({ anchorEl, isOpen, closeMenu, options }) => {
  return (
    <Menu anchorEl={anchorEl} keepMounted open={isOpen} onClose={closeMenu} className="teambook-default-menu">
      {options.map((option, i) => (
        <div key={i} className="teambook-default-menu__option" onClick={() => option.onClick()} style={option.style}>
          <p>{option.text}</p>
        </div>
      ))}
    </Menu>
  );
};

export default TeambookMenuComponent;
