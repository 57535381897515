import api_logo from "../../../default_images/teambook_api.png";
import React from "react";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import Api from "../../../../Api";
import { useTranslation } from "react-i18next";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../../stores/accountStore";
import { useNotificationStore } from "../../../../stores/notificationStore";

const ApiIntegrationBlock = () => {
  const { t } = useTranslation();
  const [account, fetchAccount] = useAccountStore((state) => [state.account, state.fetchAccount], shallow);
  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);
  const generateToken = () => {
    Api.Public.generate_token().then((response) => {
      setInAppNotification(t("notifications.settings.integrations.token_generate"));
      fetchAccount();
    });
  };

  return (
    <div className="integration-block tb-integration">
      <div className="integration-name">
        <TeambookIcon name={icons.TEAMBOOK_API_LOGO} alt="api logo" />
      </div>

      <div className="integration-status">
        <a href="https://teambook.docs.apiary.io/" target="_blank" rel="noopener noreferrer">
          Documentation
        </a>
      </div>

      <div className="tb-integration__token">
        <p>token: {account.api_token ? account.api_token : "None"}</p>
      </div>

      <TeambookOutlinedButton
        onClick={generateToken}
        variant="contained"
        text={t("settings.integration.generate_token")}
        style={{
          minWidth: "171px",
        }}
      />
    </div>
  );
};

export default ApiIntegrationBlock;
