export default [
  "users_updated",
  "users_created",
  "users_deleted",
  "users_exported",
  "users_deactivated",
  "users_activated",
  "projects_created",
  "projects_updated",
  "projects_deleted",
  "projects_exported",
  "bookings_created",
  "bookings_updated",
  "bookings_deleted",
  "bookings_exported",
  "bookings_copied",
  "reverted_action",
  "capacity_user_created",
  "capacity_project_created",
  "capacity_exported",
  "logs_created",
  "logs_updated",
  "logs_deleted",
  "logs_uploaded",
  "logs_approved",
  "logs_exported",
  "clients_created",
  "clients_updated",
  "tags_created",
  "tags_updated",
  "tags_deleted",
  "tasks_created",
  "tasks_updated",
  "logged_in",
  "subscription_changed",
];
