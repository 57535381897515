import { Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import UsersPage from "../components/users/UsersPage";
import ProjectsPage from "../components/projects/ProjectsPage";
import Profile from "../components/profile/Profile";
import Dashboard from "../components/analytics";
import Reporting from "../components/analytics/reporting/Reporting";
import ActualsPage from "../components/actuals/ActualsPage";
import ApprovalPage from "../components/actuals/approval_mode/ApprovalPage";
import PlannerPage from "../components/planner/users_planner/UsersPlanner";
import GroupedProjectsPage from "../components/planner/grouped_projects_planner/GroupedPlanner";
import CapacityPlanning from "../components/capacity_planning";
import MyWeekPage from "../components/my_week";
import SlackInstalled from "../components/integrations/SlackInstalled";
import RegisterInvited from "../components/register/RegisterInvited";
import Register from "../components/register/Register";
import Login from "../components/login/Login";
import VatReport from "../components/administration/vat_report/VatReport";
import WhatsNew from "../components/administration/whats_new/WhatsNew";
import Administration from "../components/administration";
import NoTeams from "../components/default_components/NoTeams";
import PlannerToPdf from "../components/planner/planner_to_pdf/PlannerToPdf";
import NoMatch from "../components/default_components/NoMatch";
import Projection from "../components/share_project/project_sharing";
import ClientProjection from "../components/share_project/client_sharing";
import Settings from "../components/organization_settings/Settings";
import lsKeys from "../components/default_values/defaultKeys";
import LocalesPage from "../components/administration/locales/LocalesPage";
import StatisticsPage from "../components/administration/statistics/StatisticsPage";
import { useProfileStore } from "../stores/profileStore";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../stores/accountStore";
import LoadingComponent from "../components/default_components/LoadingComponent";
import GenerateSandbox from "../components/sandbox/GenerateSandbox";

const LAST_VISITED_PAGE = localStorage.getItem(lsKeys.LAST_VISITED_PAGE);

const routes = (role, isAdminRole, setIsAdminRole) => [
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Navigate to={`${LAST_VISITED_PAGE || "/planners"}`} />
      </PrivateRoute>
    ),
  },
  {
    path: "/users",
    element: (
      <PlannerRoute role={role}>
        <UsersPage />
      </PlannerRoute>
    ),
  },
  {
    path: "/users/:id",
    element: (
      <PlannerRoute role={role}>
        <UsersPage />
      </PlannerRoute>
    ),
  },
  {
    path: "/projects",
    element: (
      <PlannerRoute role={role}>
        <ProjectsPage />
      </PlannerRoute>
    ),
  },
  {
    path: "/settings",
    element: (
      <AdminRoute role={role} isAdminRole={isAdminRole}>
        <Settings />
      </AdminRoute>
    ),
  },
  {
    path: "/profile",
    element: (
      <PrivateRoute>
        <Profile />
      </PrivateRoute>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <PlannerRoute role={role}>
        <Dashboard />
      </PlannerRoute>
    ),
  },
  {
    path: "/actuals/dashboard",
    element: (
      <PlannerRoute role={role}>
        <Dashboard type={"actuals"} />
      </PlannerRoute>
    ),
  },
  {
    path: "/dashboard/reporting",
    element: (
      <PlannerRoute role={role}>
        <Reporting />
      </PlannerRoute>
    ),
  },
  {
    path: "/actuals",
    element: (
      <PlannerRoute role={role}>
        <ApprovalPage />
      </PlannerRoute>
    ),
  },
  {
    path: "/actuals/time_logging",
    element: (
      <PrivateRoute role={role}>
        <ActualsPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/planners",
    element: (
      <PrivateRoute role={role}>
        <PlannerPage isAdminRole={isAdminRole} setIsAdminRole={setIsAdminRole} />
      </PrivateRoute>
    ),
  },
  {
    path: "/planners/:id/:date",
    element: (
      <PrivateRoute role={role}>
        <PlannerPage isAdminRole={isAdminRole} setIsAdminRole={setIsAdminRole} />
      </PrivateRoute>
    ),
  },
  {
    path: "/grouped_planners",
    element: (
      <PrivateRoute role={role}>
        <GroupedProjectsPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/grouped_planners/:id/:date",
    element: (
      <PrivateRoute role={role}>
        <GroupedProjectsPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/capacity",
    element: (
      <PrivateRoute role={role}>
        <CapacityPlanning />
      </PrivateRoute>
    ),
  },
  {
    path: "/capacity/user",
    element: (
      <PrivateRoute role={role}>
        <CapacityPlanning type={"user"} />
      </PrivateRoute>
    ),
  },
  {
    path: "/capacity/project",
    element: (
      <PrivateRoute role={role}>
        <CapacityPlanning type={"project"} />
      </PrivateRoute>
    ),
  },
  {
    path: "/capacity_planning",
    element: (
      <PrivateRoute role={role}>
        <CapacityPlanning />
      </PrivateRoute>
    ),
  },
  {
    path: "/my-week",
    element: (
      <PrivateRoute role={role}>
        <MyWeekPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/my-week/:date",
    element: (
      <PrivateRoute role={role}>
        <MyWeekPage />
      </PrivateRoute>
    ),
  },
  {
    path: "/slack_success",
    element: (
      <PrivateRoute role={role}>
        <SlackInstalled />
      </PrivateRoute>
    ),
  },
  {
    path: "/invitation/:token",
    element: <RegisterInvited />,
  },
  {
    path: "/invitation/:token/:lang",
    element: <RegisterInvited />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/register/:lang",
    element: <Register />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/login/:lang",
    element: <Login />,
  },
  {
    path: "/projection/:url_token",
    element: <Projection />,
  },
  {
    path: "/client_projection/:id",
    element: <ClientProjection />,
  },
  {
    path: "/administration/vat_report",
    element: (
      <PrivateAdministrationRoute>
        <VatReport />
      </PrivateAdministrationRoute>
    ),
  },
  {
    path: "/administration/statistics",
    element: (
      <PrivateAdministrationRoute>
        <StatisticsPage />
      </PrivateAdministrationRoute>
    ),
  },
  {
    path: "/administration/whats_new",
    element: (
      <PrivateAdministrationRoute>
        <WhatsNew />
      </PrivateAdministrationRoute>
    ),
  },
  {
    path: "/administration/locales",
    element: (
      <PrivateAdministrationRoute>
        <LocalesPage />
      </PrivateAdministrationRoute>
    ),
  },
  {
    path: "/administration",
    element: (
      <PrivateRoute role={role}>
        <Administration />
      </PrivateRoute>
    ),
  },
  {
    path: "/team_not_found",
    element: (
      <PrivateRoute role={role}>
        <NoTeams />
      </PrivateRoute>
    ),
  },
  {
    path: "/planner_to_pdf/:teamId",
    element: (
      <PrivateRoute role={role}>
        <PlannerToPdf />
      </PrivateRoute>
    ),
  },
  {
    path: "/planner_to_pdf/:teamId/:date",
    element: (
      <PrivateRoute role={role}>
        <PlannerToPdf />
      </PrivateRoute>
    ),
  },
  {
    path: "/generate_sandbox/:language",
    element: (
      <SandboxRoute>
        <GenerateSandbox />
      </SandboxRoute>
    ),
  },
];

function PrivateRoute({ children, ...rest }) {
  const [profile] = useProfileStore((state) => [state.profile], shallow);
  const [account] = useAccountStore((state) => [state.account], shallow);

  const [isLoaded, setIsLoaded] = useState(account && profile);

  useEffect(() => setIsLoaded(profile && account), [account, profile]);

  if (!localStorage.getItem("Auth-Token")) {
    if (process.env.REACT_APP_ENV === "sandbox") {
      return <Navigate to="/generate_sandbox" replace />;
    } else {
      return <Navigate to="/login" replace />;
    }
  }

  if (isLoaded) {
    return children;
  } else {
    return <LoadingComponent />;
  }
}

function AdminRoute({ children, role, isAdminRole }) {
  if (role === "admin" || isAdminRole) {
    return <PrivateRoute>{children}</PrivateRoute>;
  } else {
    return (
      <PrivateRoute>
        <NoMatch type="noAccess" role={role} />
      </PrivateRoute>
    );
  }
}

function PlannerRoute({ children, role }) {
  if (role === "admin" || role === "planner") {
    return <PrivateRoute>{children}</PrivateRoute>;
  } else {
    return (
      <PrivateRoute>
        <NoMatch type="noAccess" role={role} />
      </PrivateRoute>
    );
  }
}

function SandboxRoute({ children }) {
  if (process.env.REACT_APP_ENV !== "production") {
    return children;
  } else {
    return <Navigate to="/login" replace />;
  }
}

function PrivateAdministrationRoute({ children, ...rest }) {
  if (!localStorage.getItem("tb-admin-token")) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

export default routes;
