import { useTranslation } from "react-i18next";
import { useState } from "react";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";

export default function InfoTableColumnHeader({
  sortingField,
  columnField,
  sortFunction,
  sortingOrder,
  columnTitle,
  secondFieldText,
  style,
  className,
  sortable = true,
  isProjectsPage = false,
  setSortOrder,
  isCustomField = false,
}) {
  const { t } = useTranslation();
  const localeString = `${isProjectsPage ? "projects" : "users"}.sort_by_${columnField
    .split(" ")
    .join("_")
    .toLowerCase()}`;
  const [isHovered, setIsHovered] = useState(false);

  const showArrow =
    isHovered || (sortable && sortingField === columnField && (sortingOrder === "a" || sortingOrder === "z"));

  return (
    <div
      onClick={() => {
        if (sortable) {
          sortFunction(columnField, sortingOrder);
        }

        if (sortingField !== columnField && sortable) {
          setSortOrder("a");
        }
      }}
      className={className}
      style={style}
      onMouseEnter={() => {
        if (sortable) {
          setIsHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (sortable) {
          setIsHovered(false);
        }
      }}
    >
      <div style={{ display: "flex", width: "100%", position: "relative", alignItems: "center" }}>
        <p style={{ textOverflow: "ellipsis", width: "fit-content" }}>{columnTitle}</p>

        {showArrow && (
          <TeambookIcon
            name={icons.LONG_ARROW}
            style={{
              transform: sortingOrder === "a" ? "rotate(0)" : "rotate(180deg)",
            }}
            tooltipTitle={isCustomField ? "" : t(localeString)}
          />
        )}
      </div>
      {secondFieldText && <p>{secondFieldText}</p>}
    </div>
  );
}
