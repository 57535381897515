import { useTranslation } from "react-i18next";
import { icons } from "../default_images/IconsList";
import { WhiteTooltip } from "./Tooltips";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { useZoomStore } from "../../stores/planner";
import { GetWeekMonday, OffsetDays } from "../../lib/DateUtils";
import { DateTime } from "luxon";
import { offsetWeeksForPlanner } from "../planner/lib/plannerFunctions";

export const TodayButton = ({ setDate, page = "default", actualsZoom, style }) => {
  const { t } = useTranslation();
  const { zoom } = useZoomStore();

  const calculateToday = {
    default: () => DateTime.now().startOf("month"),
    planner: () => OffsetDays(new Date(), -1 * offsetWeeksForPlanner(zoom) * 7),
    capacity: () => DateTime.now().startOf("month"),
    actuals_timesheet: () => DateTime.now().startOf(actualsZoom),
  };

  return (
    <WhiteTooltip title={t("today")}>
      <div style={style} className="control-bar__today-button" onClick={() => setDate(calculateToday[page]())}>
        <TeambookIcon style={{ width: 20, height: 20 }} name={icons.TODAY} color="darkblue_new" />
      </div>
    </WhiteTooltip>
  );
};
