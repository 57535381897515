import React, { useEffect, useState } from "react";
import "./header_modals.scss";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useTranslation } from "react-i18next";
import Api from "../../../Api";
import { DateTime } from "luxon";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import TeambookDatePicker from "../../default_components/teambook_date_selector/DatePicker";
import { TeambookUsersPicker } from "../../default_components/TeambookDefaultPickers";
import { TeambookReactSelect } from "../../default_components/TeambookReactSelect";
import EventTypes from "../../default_values/EventTypes";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import { useNotificationStore } from "../../../stores/notificationStore";
import { shallow } from "zustand/shallow";

const EventLogsModal = ({ setOpen }) => {
  const { t } = useTranslation();

  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);

  const [eventLogs, setEventLogs] = useState(null);
  const [date, setDate] = useState(DateTime.now());
  const [userId, setUserId] = useState();
  const [eventType, setEventType] = useState();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    Api.EventLogs.get(date.toISODate(), userId, eventType).then((response) => {
      setEventLogs(response.data);
    });
  }, [date, userId, eventType]);

  useEffect(() => {
    Api.Users.all().then((response) => {
      setUsers(response.data);
    });
  }, []);

  const closeWindow = () => {
    setOpen(false);
  };

  const userOptions = () => {
    const userList = users
      .sort((x, y) => (x.last_name > y.last_name ? 1 : -1))
      .map((act) => ({
        value: act.id,
        label: act.name,
      }));

    return [{ value: null, label: t("users.all_users") }, ...userList];
  };

  const eventTypeOptions = () => {
    return [
      { value: null, label: t("header.event_logs.all_events") },
      ...EventTypes.map((type) => ({
        value: type,
        label: type,
      })),
    ];
  };

  const exportLogs = () => {
    Api.EventLogs.export().then(() => setInAppNotification(t("header.event_logs.exported")));
  };

  return (
    <Dialog className="tb-whats-new-dialog" onClose={closeWindow} open>
      <DialogTitle>
        <p className="whats-new__header">{t("header.event_logs.title")}</p>
        <TeambookIcon name={icons.CROSS} onClick={closeWindow} />
      </DialogTitle>

      <DialogContent className="planner-settings-dialog__content">
        <div className="event-logs__filters">
          <TeambookDatePicker
            onDayClick={(date) => setDate(date)}
            from={date}
            range={1}
            type="default"
            variant="inline"
            formatStart={"d MMM, yyyy"}
            width={86}
            height={24}
          />

          <div className="event-logs__select-option">
            <p>{t("header.users")}</p>
            <TeambookReactSelect
              isSearchable={true}
              options={userOptions()}
              onChange={(option) => {
                setUserId(option.value);
              }}
              height={44}
              maxMenuHeight={180}
              width={200}
              value={userOptions().find((val) => val.value === userId)}
            />
          </div>

          <div className="event-logs__select-option">
            <p>{t("header.event_logs.event_type")}</p>
            <TeambookReactSelect
              isSearchable={true}
              options={eventTypeOptions()}
              onChange={(option) => {
                setEventType(option.value);
              }}
              height={44}
              maxMenuHeight={180}
              width={200}
              value={eventTypeOptions().find((val) => val.value === userId)}
            />
          </div>
        </div>

        <div className="event-logs__rows">
          {eventLogs?.map((eventLog) => (
            <p className="event-logs__row">{eventLog}</p>
          ))}

          {eventLogs?.length === 0 && <p className="event-logs__no-logs">{t("header.no_logs")}</p>}

          {eventLogs === null && <p className="event-logs__no-logs">{t("dashboard.loading")}...</p>}
        </div>
      </DialogContent>

      <DialogActions>
        <TeambookBlueButton
          onClick={exportLogs}
          variant="contained"
          disabledAnimations
          htmlText={
            <div className="event-logs__export">
              <TeambookIcon name={icons.EXCEL_FILE} />
              <p> {t("header.event_logs.export_data")}</p>
            </div>
          }
        />
      </DialogActions>
    </Dialog>
  );
};

export default EventLogsModal;
