import DeleteBookingsRequest from "../requests/DeleteBookingsRequest";
import TooltipChanger from "./TooltipChanger";
import $ from "jquery";
import { DateTime } from "luxon";
import { useDateStore, useZoomStore } from "../../../stores/planner";
import { GetDaysInZoom, OffsetDays } from "../../../lib/DateUtils";

const _deleteBookingsRequest = (
  deletionBookings,
  setBookingsEditing,
  setRepetitionQuestionDialogOpened,
  date,
  zoom,
  setBookings,
  closeCreationWindow,
  bookings,
  setEditWindowOpened,
  closeEditWindow,
  teams,
  teamId,
  usersRef,
  filteredUsers,
  setProjects,
  setActiveProjects,
  projects,
  activeProjects,
  tooltipRef
) => {
  let skipQuestion = true;
  setBookingsEditing(deletionBookings);

  deletionBookings.forEach((booking) => {
    if (booking.repetition && skipQuestion) {
      setRepetitionQuestionDialogOpened(true);
      skipQuestion = false;
    }
  });

  if (skipQuestion) {
    //TODO: fix booking deletion hashes shit
    DeleteBookingsRequest(
      deletionBookings,
      date,
      zoom,
      setBookings,
      closeCreationWindow,
      bookings,
      setEditWindowOpened,
      false,
      closeEditWindow,
      teams,
      teamId,
      usersRef,
      filteredUsers,
      setProjects,
      setActiveProjects,
      projects,
      activeProjects
    );
  }
  TooltipChanger(tooltipRef.current[0]);
};

const offsetWeeksForPlanner = (zoom) => {
  switch (zoom) {
    case 14:
      return 1;
    case 28:
      return 2;
    case 90:
      return 4;
    default:
      return 0;
  }
};

// WEEKENDS AMOUNT * WIDTH OPF WEEKENDS + DAYS AMOUNT * DAYS WIDTH
const _scrollPlannerToDate = (zoom, weekendsHidden) => {
  let dayWidth = 10;

  switch (zoom) {
    case 14:
      dayWidth = 140;
      break;
    case 28:
      dayWidth = 72;
      break;
    case 90:
      dayWidth = 20;
      break;
    default:
      break;
  }
  const weekendWidth = weekendsHidden ? 10 : dayWidth;

  const weekendDays = offsetWeeksForPlanner(zoom) * 2;
  const workDays = offsetWeeksForPlanner(zoom) * 5;

  const scrollValue = weekendDays * weekendWidth + workDays * dayWidth + 1;

  if ($("#planner-scroll-list")?.scrollLeft() === 0 && zoom > 1) {
    $("#planner-scroll-list").animate(
      {
        scrollLeft: scrollValue,
      },
      500
    );
  }
};

const copyBookingAvailable = (bookings) => {
  if (bookings.length === 0) return true;

  const userId = bookings[0].user_id;
  const weekStartDate = DateTime.fromISO(bookings[0].date).startOf("week").toISODate();

  return bookings.every(
    (booking) =>
      booking.user_id === userId && DateTime.fromISO(booking.date).startOf("week").toISODate() === weekStartDate
  );
};

const plannerScrolled = (e) => {
  const target = e.target;
  const scrollRight = target.scrollWidth - target.scrollLeft - target.clientWidth;
  const zoom = useZoomStore.getState().zoom;

  if (scrollRight < 10 && zoom > 1) {
    const weeksOffset = offsetWeeksForPlanner(zoom);
    const daysOffset =
      Math.floor(
        (target.clientWidth - 160) / ($(".user-row-week").width() + $(".user-row-weekends").width()) + weeksOffset
      ) * 7;

    useDateStore.getState().setDate(OffsetDays(useDateStore.getState().date, GetDaysInZoom(zoom) - daysOffset));
    return;
  }

  if (target.scrollLeft === 0 && zoom > 1) {
    useDateStore.getState().setDate(OffsetDays(useDateStore.getState().date, -offsetWeeksForPlanner(zoom) * 7));
  }
};

export { _deleteBookingsRequest, _scrollPlannerToDate, copyBookingAvailable, offsetWeeksForPlanner, plannerScrolled };
