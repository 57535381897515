import React from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "@mui/material";

const UsersSyncTab = ({ open, integration, changePersonioIntegration }) => {
  const { t } = useTranslation();

  if (!open) return null;

  return (
    <div className="personio__sync-setting">
      <div className="personio__main-setting">
        <p>{t("settings.integration.personio.sync_users")}</p>
        <Switch
          checked={integration?.users_sync}
          onChange={(event) => {
            if (event.target.checked) {
              changePersonioIntegration("users_sync", true);
            } else {
              changePersonioIntegration("users_sync", false);
            }
          }}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </div>

      <p className="personio__description">{t("settings.integration.personio.sync_users_description")}</p>

      <div className="personio__main-setting">
        <p>{t("settings.integration.personio.invite_users")}</p>
        <Switch
          checked={integration?.invite_users}
          onChange={(event) => {
            if (event.target.checked) {
              changePersonioIntegration("invite_users", true);
            } else {
              changePersonioIntegration("invite_users", false);
            }
          }}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
      </div>
    </div>
  );
};

export default UsersSyncTab;
