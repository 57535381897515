export default function YearSeparator({ right = -1 }) {
  return (
    <div
      style={{
        position: "absolute",
        right: right,
        zIndex: 1,
        width: 1,
        background: "var(--text-12)",
        top: -1,
        height: "calc(100% + 2px)",
      }}
    />
  );
}
