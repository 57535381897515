import React from "react";
import UsersProfileImage from "../../../default_components/UsersProfileImage";
import ChangeUserPhoto from "../ChangeUserPhoto";
import ShowFields from "./ShowFields";
import EditOrCreateFields from "./EditOrCreateFields";
import GeneralTabInfo from "./GeneralTabInfo";
import ScheduleTabInfo from "./ScheduleTabInfo";
import { useTranslation } from "react-i18next";
import TeambookNotificationComponent from "../../../default_components/TeambookNotificationComponent";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { shallow } from "zustand/shallow";
import TeambookOptionsLine from "../../../default_components/TeambookOptionsLine";
import { useTeambookDataStore } from "../../../../stores/users";

const UsersData = ({
  viewType,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  billable,
  setBillable,
  role,
  setRole,
  timeZone,
  setTimeZone,
  tagName,
  setTagName,
  userTeams,
  setUserTeams,
  closeErrorMessage,
  errorField,
  errorMessage,
  roleOptions,
  userBillability,
  getAllUsers,
  schedule,
  setSchedule,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  tab,
  setTab,
  notificationSetting,
  setNotificationSetting,
  holidays,
  selectedHoliday,
  setSelectedHoliday,
  notes,
  setNotes,
  setUploadPhotoProps,
  customWorkingHours,
  setCustomWorkingHours,
  departmentId,
  setDepartmentId,
}) => {
  const { t } = useTranslation();
  const [tags, teams, user] = useTeambookDataStore((state) => [state.tags, state.teams, state.chosenUser], shallow);

  const userField = (
    name,
    value,
    setValue,
    type,
    id = "none",
    options = [],
    isDisabled = false,
    defineDates,
    setDefineDate,
    placeholder = ""
  ) => {
    if (viewType === "show") {
      return ShowFields(name, value, type, userTeams);
    } else {
      return EditOrCreateFields(
        name,
        value,
        setValue,
        type,
        id,
        options,
        tags,
        teams,
        closeErrorMessage,
        startDate,
        defineDates,
        setDefineDate,
        placeholder,
        isDisabled
      );
    }
  };

  const avatarComponent = () => {
    if (viewType === "show") {
      return <UsersProfileImage size={100} user={user} userImage={user.avatars["100"]} className="user-form__avatar" />;
    } else if (viewType === "edit" || viewType === "create")
      return (
        <ChangeUserPhoto
          viewType={viewType}
          className="user-form__change-photo"
          user={user}
          getAllUsers={getAllUsers}
          setUploadPhotoProps={setUploadPhotoProps}
        />
      );
  };

  const tabs = [
    {
      value: "general",
      text: t("users.general_info"),
      icon: <TeambookIcon name={icons.USER} color={"darkblue_new"} />,
      onClick: () => setTab("general"),
    },
    {
      value: "schedule",
      text: t("users.schedule"),
      icon: <TeambookIcon name={icons.FILLED_CALENDAR} color={"darkblue_new"} />,
      onClick: () => setTab("schedule"),
    },
    {
      value: "notifications",
      text: t("profile.notifications.name"),
      icon: <TeambookIcon name={icons.NOTIFICATION} color={"darkblue_new"} />,
      onClick: () => setTab("notifications"),
    },
  ];

  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <div className="flex users-data__tabs">
        <TeambookOptionsLine options={tabs} selectedOption={tab} />
      </div>

      {tab === "general" && (
        <GeneralTabInfo
          userField={userField}
          viewType={viewType}
          firstName={firstName}
          avatarComponent={avatarComponent}
          userTeams={userTeams}
          setUserTeams={setUserTeams}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          billable={billable}
          setBillable={setBillable}
          role={role}
          setRole={setRole}
          timeZone={timeZone}
          setTimeZone={setTimeZone}
          tagName={tagName}
          setTagName={setTagName}
          closeErrorMessage={closeErrorMessage}
          errorField={errorField}
          errorMessage={errorMessage}
          roleOptions={roleOptions}
          userBillability={userBillability}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          notes={notes}
          setNotes={setNotes}
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
        />
      )}

      {tab === "schedule" && (
        <ScheduleTabInfo
          schedule={schedule}
          setSchedule={setSchedule}
          kind={viewType}
          holidays={holidays}
          selectedHoliday={selectedHoliday}
          setSelectedHoliday={setSelectedHoliday}
          customWorkingHours={customWorkingHours}
          setCustomWorkingHours={setCustomWorkingHours}
        />
      )}

      {tab === "notifications" && (
        <div className="users__notifications">
          <TeambookNotificationComponent
            notificationSetting={notificationSetting}
            setNotificationSetting={setNotificationSetting}
            blockEditing={viewType === "show"}
          />
        </div>
      )}
    </div>
  );
};

export default UsersData;
