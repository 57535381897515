import { TeambookTeamsPicker } from "../../../default_components/TeambookDefaultPickers";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import { TeambookReactSelect } from "../../../default_components/TeambookReactSelect";
import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TeambookCheckbox } from "../../../default_components/TeambookCheckbox";
import RichTextEditor from "../../../default_components/rich_text_editor/RichTextEditor";
import "../../../organization_settings/settings.scss";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { TextField } from "@mui/material";
import MuiDatePicker from "../../../default_components/MuiDatePicker";
import { TeambookAuthorizationPicker } from "../../../default_components/TeambookAuthorizationPicker";

const phoneRegex = /^[+0-9][0-9]*$/;

const EditOrCreateFields = (
  name,
  value,
  setValue,
  type,
  id,
  options,
  tags,
  teams,
  closeErrorMessage,
  startDate,
  defineDates,
  setDefineDates,
  placeholder,
  isDisabled
) => {
  if (type === "teams") {
    return (
      <>
        <p className="user-form__field">{name}</p>

        <p className="user-form__value">
          <TeambookTeamsPicker
            width={260}
            value={value}
            onChange={(team) => {
              setValue(team);
              closeErrorMessage();
            }}
            teams={teams}
            type="multiple"
            closeMenuOnSelect={false}
            isSearchable={false}
            grayPlaceholder
          />
        </p>
      </>
    );
  } else if (type === "text") {
    return (
      <>
        <p className="user-form__field ">{name}</p>

        <TeambookTextForm
          id={id}
          width={260}
          className="user-form__field-input"
          fieldValue={value}
          placeholder={placeholder}
          onChange={(text) => setValue(text.length > 0 ? (text[0]?.toUpperCase() || "") + text.slice(1) : "")}
        />
      </>
    );
  } else if (type === "email") {
    return (
      <>
        <p className="user-form__field">{name}</p>

        <TeambookTextForm
          id={id}
          width={260}
          className="user-form__field-input"
          fieldValue={value}
          onChange={setValue}
        />
      </>
    );
  } else if (type === "array") {
    return (
      <>
        <p className="user-form__field">{name}</p>

        <p className="user-form__value">
          <TeambookReactSelect
            width={260}
            value={value}
            onChange={(option) => setValue(option)}
            options={options}
            grayPlaceholder
          />
        </p>
      </>
    );
  } else if (type === "role") {
    return (
      <>
        <p className="user-form__field">{name}</p>

        <p className="user-form__value">
          <TeambookAuthorizationPicker value={value} setValue={setValue} options={options} isDisabled={isDisabled} />
        </p>
      </>
    );
  } else if (type === "phone") {
    return (
      <>
        <p className="user-form__field">{name}</p>

        <TeambookTextForm
          id={id}
          width={260}
          className={"user-form__field-input"}
          fieldValue={value}
          onChange={(text) => (text.length > 0 ? (phoneRegex.test(text) ? setValue(text) : false) : setValue(text))}
        />
      </>
    );
  } else if (type === "tags") {
    return (
      <>
        <div className="user-form__tags-select">
          <p className="user-form__field">{name}</p>

          <TeambookReactSelect
            options={tags}
            width={260}
            isMulti
            value={value}
            onChange={(tag) => setValue(tag)}
            isSearchable={false}
            closeMenuOnSelect={false}
            type="multiple"
            styles={{
              multiValue: (base, state) => {
                return {
                  ...base,
                  backgroundColor: state.data.color + "66",
                  border: "2px solid " + state.data.color,
                  color: "var(--text-10) !important",
                };
              },
              multiValueLabel: (base, state) => ({
                ...base,
                color: "var(--text-10) !important",
              }),
            }}
            grayPlaceholder
          />
        </div>
      </>
    );
  } else if (type === "date") {
    let minDate = undefined;
    let isDateDefined = false;

    if (id === "userEndDate") {
      isDateDefined = !!defineDates.endDate;
    } else {
      isDateDefined = !!defineDates.startDate;
    }

    if (id === "userEndDate" && startDate) {
      minDate = startDate;
    }

    return (
      <div className="form__date-field" style={id === "userEndDate" ? { marginTop: "12px" } : {}}>
        <TeambookCheckbox
          checked={isDateDefined}
          onChange={() => {
            if (id === "userEndDate") {
              setDefineDates((pr) => ({ ...pr, endDate: !pr.endDate }));
            } else {
              setDefineDates((pr) => ({ ...pr, startDate: !pr.startDate }));
            }
          }}
        />

        <p
          onClick={() => {
            if (id === "userEndDate") {
              setDefineDates((pr) => ({ ...pr, endDate: !pr.endDate }));
            } else {
              setDefineDates((pr) => ({ ...pr, startDate: !pr.startDate }));
            }
          }}
          style={{ marginLeft: 10 }}
          className="user-form__field"
        >
          {name}
        </p>

        {isDateDefined && (
          <MuiDatePicker
            format={"d/MMM/yyyy"}
            value={value}
            onChange={(date) => setValue(date)}
            minDate={minDate}
            className="mui-date-picker"
            defaultValue={value}
            renderInput={(params) => <TextField placeholder=" " defaultValue=" " {...params} />}
            slotProps={{ textField: { placeholder: "dd/mm/yyyy" } }}
            formatDensity="dence"
            localeText={{
              fieldDayPlaceholder: () => "dd",
              fieldMonthPlaceholder: () => "mm",
              fieldYearPlaceholder: () => "yyyy",
            }}
          />
        )}
      </div>
    );
  } else if (type === "richText") {
    return (
      <>
        <RichTextEditor value={value || "<p></p>"} ref={setValue} />
      </>
    );
  }
};

export default EditOrCreateFields;
