import React, { useEffect, useState } from "react";
import Api from "../../../../../Api";
import { DialogActions, DialogContent } from "@mui/material";
import { useNotificationStore } from "../../../../../stores/notificationStore";
import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";
import TeambookOptionsLine from "../../../../default_components/TeambookOptionsLine";
import TimeOffSyncTab from "./TimeOffSyncTab";
import UsersSyncTab from "./UsersSyncTab";
import TeambookWhiteButton from "../../../../default_components/TeambookWhiteButton";

const ConnectedPersonio = ({ account, setPersonioModalOpened, fetchAccount }) => {
  const { t } = useTranslation();

  const [setInAppNotification] = useNotificationStore((state) => [state.setInAppNotification], shallow);

  const [teambookTimeOffIds, setTeambookTimeOffIds] = useState([]);
  const [teambookTimeOffs, setTeambookTimeOffs] = useState([]);
  const [timeOffTypes, setTimeOffTypes] = useState([]);
  const [tab, setTab] = useState("users");
  const [integration, setIntegration] = useState();

  useEffect(() => {
    Api.Integrations.Personio.getSettings().then((res) => setIntegration(res.data.settings));

    Api.Integrations.Personio.get_time_off_types().then((res) => {
      setTimeOffTypes(res.data);
      setTeambookTimeOffIds(res.data.map((type) => type.teambook_project_id));
    });

    Api.Projects.time_off().then((res) => setTeambookTimeOffs(res.data.filter((timeOff) => timeOff.active === true)));
  }, [account]);

  useEffect(() => {
    updateTimeOffMapping();
  }, [teambookTimeOffIds]);

  const updateTimeOffMapping = () => {
    Api.Integrations.Personio.update_time_off_types(
      timeOffTypes.map((type) => type.id),
      teambookTimeOffIds
    );
  };

  const deleteIntegration = () => {
    Api.Integrations.Personio.delete().then(() => {
      setPersonioModalOpened(false);
      fetchAccount();
    });
  };

  const changePersonioIntegration = (type, value) => {
    Api.Integrations.Personio.updateSettings({ [type]: value })
      .then(() => {
        setIntegration({ ...integration, [type]: value });
      })
      .then(() => setInAppNotification("Integration updated successfully"));
  };

  return (
    <>
      <DialogContent>
        <TeambookOptionsLine
          options={[
            {
              text: "Users Sync",
              value: "users",
              onClick: () => setTab("users"),
            },
            {
              text: "Time Off Sync",
              value: "time_offs",
              onClick: () => setTab("time_offs"),
            },
          ]}
          selectedOption={tab}
        />

        <TimeOffSyncTab
          open={tab === "time_offs"}
          teambookTimeOffIds={teambookTimeOffIds}
          setTeambookTimeOffIds={setTeambookTimeOffIds}
          timeOffTypes={timeOffTypes}
          teambookTimeOffs={teambookTimeOffs}
          integration={integration}
          changePersonioIntegration={changePersonioIntegration}
          updateTimeOffMapping={updateTimeOffMapping}
        />

        <UsersSyncTab
          open={tab === "users"}
          integration={integration}
          changePersonioIntegration={changePersonioIntegration}
        />
      </DialogContent>
      <DialogActions>
        <TeambookWhiteButton
          text={t("disconnect")}
          onClick={() => {
            confirm("Personio integration will be deleted, are you sure?") && deleteIntegration();
          }}
          className="disconnect-button"
        />
      </DialogActions>
    </>
  );
};

export default ConnectedPersonio;
