import React from "react";
import { useTranslation } from "react-i18next";
import TeambookScheduleComponent from "../../../default_components/schedule/TeambookScheduleComponent";
import { TeambookReactSelect } from "../../../default_components/TeambookReactSelect";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import { TeambookCheckbox } from "../../../default_components/TeambookCheckbox";
import { InfoTooltip } from "../../../default_components/InfoTooltip";
import { useTeambookDataStore } from "../../../../stores/users";
import { useAccountStore } from "../../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const ScheduleTabInfo = ({
  schedule,
  setSchedule,
  kind,
  holidays,
  selectedHoliday,
  setSelectedHoliday,
  customWorkingHours,
  setCustomWorkingHours,
}) => {
  const { t } = useTranslation();
  const [user] = useTeambookDataStore((state) => [state.chosenUser], shallow);

  const selectOptions =
    holidays?.map((holiday) => ({
      value: holiday.id,
      label: holiday.name,
    })) || [];

  const customWorkingHoursText = () => {
    if (user?.custom_working_hours) {
      return `${user.custom_working_hours}h ${t("settings.subscription.monthly")}`;
    }

    return t("no");
  };

  selectOptions.push({ value: null, label: t("projects.none") });

  return (
    <div className="users__schedule-tab">
      <div className="holidays">
        <p>{t("dashboard.holidays")}:</p>

        {kind === "show" ? (
          <p style={{ textAlign: "end" }}>{selectedHoliday?.name || t("projects.none")}</p>
        ) : (
          <TeambookReactSelect
            height={44}
            value={selectOptions.filter((opt) => opt.value === selectedHoliday)[0]}
            options={selectOptions}
            onChange={(value) => {
              setSelectedHoliday(value.value);
            }}
            width="220px"
            grayPlaceholder
          />
        )}
      </div>

      <div className="users__custom-working-hours">
        <div
          className="users__custom-working-hours__title"
          onClick={() => (customWorkingHours ? setCustomWorkingHours(null) : setCustomWorkingHours(160))}
        >
          {kind !== "show" && (
            <TeambookCheckbox
              checked={!!customWorkingHours}
              onChange={() => {
                customWorkingHours ? setCustomWorkingHours(null) : setCustomWorkingHours(160);
              }}
            />
          )}
          <p>{t("users.custom_working_hours")}</p>
          <InfoTooltip
            style={{ width: 16, height: 16, marginTop: 4 }}
            content={<p style={{ marginBottom: "0" }}>{t("users.custom_working_hours_info")}</p>}
          />
        </div>

        {kind === "show" ? (
          <p>{customWorkingHoursText()}</p>
        ) : (
          !!customWorkingHours && (
            <div className="users__custom-working-hours__input">
              <TeambookTextForm
                className="user-form__field-input"
                fieldValue={customWorkingHours}
                onChange={(value) => {
                  if (value > 0) {
                    setCustomWorkingHours(value);
                  }
                }}
                type={"number"}
                style={{ width: "60px" }}
              />
              <p>hours per month</p>
            </div>
          )
        )}
      </div>

      <TeambookScheduleComponent schedule={schedule} setSchedule={setSchedule} kind={kind} />
    </div>
  );
};

export default ScheduleTabInfo;
