import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Api from "../../../../Api";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import FormErrorMessage from "../../../default_components/FormErrorMessage";
import GetAllMilestones from "../../requests/GetAllMilestones";
import moment from "moment";
import { DialogTitle } from "@mui/material";
import { useMilestonesStore } from "../../../../stores/planner";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import TeambookDatePicker from "../../../default_components/teambook_date_selector/DatePicker";

const MilestoneCreation = ({
  project,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  dialogOpened,
  closeDialog,
  setMilestones,
}) => {
  const { t } = useTranslation();

  const { creationOrder: order } = useMilestonesStore();

  const [milestoneName, setMilestoneName] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const isCorrectDates = startDate < endDate?.plus({ days: 1 });

  moment.locale(t(localStorage.getItem("tb-lang")));

  const createMilestone = () => {
    if (isCorrectDates) {
      setErrorMessage(null);

      if (milestoneName.length < 3 || milestoneName.length > 30) {
        setErrorMessage("Milestone name must be 3 to 30 characters long.");
      } else {
        Api.Milestones.create({
          name: milestoneName,
          project_id: project.id,
          start_date: startDate,
          end_date: endDate,
          order: order,
        })
          .then(() => {
            GetAllMilestones(setMilestones);
            setMilestoneName("");
            closeDialog();
          })
          .catch((error) => {
            setErrorMessage(error.response.data.error);
          });
      }
    }
  };

  return (
    <Dialog
      open={dialogOpened}
      onClose={() => {
        closeDialog();
        setMilestoneName("");
        setErrorMessage(null);
      }}
      aria-labelledby="deactivation-dialog"
      fullWidth={true}
      sx={{
        "& .MuiDialog-paper": {
          width: 490,
          height: 294,
        },
      }}
      className="tb-default-dialog milestone-modal"
    >
      <DialogTitle id="add-user-dialog">
        <p>{t("planning.milestones.create")}</p>
        <TeambookIcon
          name={icons.CROSS}
          onClick={() => {
            closeDialog();
            setMilestoneName("");
            setErrorMessage(null);
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div className="milestone-creating__creating">
          <TeambookTextForm
            id={"milestoneName"}
            fieldName={t("planning.milestones.name")}
            onChange={(text) => setMilestoneName(text)}
            type={"text"}
            fieldValue={milestoneName}
            width={"78%"}
            placeholder={"Milestone"}
          />
        </div>

        <div className="milestone-creating__text-row">
          <div className="milestone-date-picker__container">
            <p>{t("projects.start_date")}</p>

            <TeambookDatePicker
              onDayClick={(date) => setStartDate(date)}
              from={startDate}
              range={1}
              type="default"
              variant="inline"
              formatStart={"MMM d, yyyy"}
              width={86}
              height={24}
            />
          </div>

          <div className="milestone-date-picker__container">
            <p>{t("projects.end_date")}</p>

            <TeambookDatePicker
              onDayClick={(date) => setEndDate(date)}
              from={endDate}
              range={1}
              type="default"
              variant="inline"
              formatStart={"MMM d, yyyy"}
              width={86}
              height={24}
            />
          </div>
        </div>

        {errorMessage !== null ? (
          <FormErrorMessage text={errorMessage} closeErrorMessage={() => setErrorMessage(null)} />
        ) : null}
      </DialogContent>
      <DialogActions className="milestone-creating__actions">
        <TeambookBlueButton
          id="createMilestoneButton"
          text={t("create")}
          disabled={!isCorrectDates}
          onClick={() => createMilestone()}
        />
      </DialogActions>
    </Dialog>
  );
};

export default MilestoneCreation;
