const STRING_FIELDS = ["name", "code", "client_name", "kind", "manager", "status", "business_unit"];
const INTEGER_FIELDS = [
  "bookings_duration",
  "estimated",
  "actual_logs_duration",
  "user_capacity_duration",
  "estimated_capacity",
  "estimated_logs",
];
const DATE_FIELDS = ["end_date", "start_date"];

export const sortProjects = (sortField, sortOrder, displayingProjects, account) => {
  if (STRING_FIELDS.includes(sortField)) {
    return sortText(sortOrder, displayingProjects, sortField);
  } else if (INTEGER_FIELDS.includes(sortField)) {
    return sortIntegers(sortOrder, displayingProjects, sortField);
  } else if (DATE_FIELDS.includes(sortField)) {
    return sortDates(sortOrder, displayingProjects, sortField);
  } else {
    return sortCustomField(sortOrder, displayingProjects, sortField, account);
  }
};

const objectMapping = (object, field) => {
  if (field === "manager") {
    return object.manager?.last_name;
  } else if (field === "estimated_capacity") {
    return object.estimated;
  } else if (field === "estimated_logs") {
    return object.estimated;
  }

  return object[field];
};

const compareNames = (a, b) => {
  const nameA = objectMapping(a, "name") || "";
  const nameB = objectMapping(b, "name") || "";
  return nameA.toUpperCase() > nameB.toUpperCase() ? 1 : -1;
};

const sortCustomField = (sortOrder, displayingProjects, sortField, account) => {
  const { custom_project_fields } = account;

  const getCustomValue = (project) => {
    return JSON.parse(project.custom_fields || "{}")[
      `field_value_${custom_project_fields.findIndex((field) => field === sortField) + 1}`
    ];
  };

  return [
    ...displayingProjects
      .filter((project) => getCustomValue(project))
      .sort((a, b) => {
        if (sortOrder === "a") {
          return getCustomValue(a) > getCustomValue(b) ? 1 : -1;
        } else {
          return getCustomValue(a) < getCustomValue(b) ? 1 : -1;
        }
      }),
    ...displayingProjects.filter((project) => !getCustomValue(project)),
  ];
};

const sortText = (sortOrder, displayingProjects, sortField) => {
  const sortingNominator = sortOrder === "a" ? 1 : -1;

  return [...displayingProjects].sort((a, b) => {
    const mainField = objectMapping(a, sortField) || "";
    const secondaryField = objectMapping(b, sortField) || "";

    if (mainField.toUpperCase() > secondaryField.toUpperCase()) return sortingNominator;
    if (mainField.toUpperCase() < secondaryField.toUpperCase()) return -sortingNominator;

    return compareNames(a, b);
  });
};

const sortIntegers = (sortOrder, displayingProjects, sortField) => {
  const sortingNominator = sortOrder === "a" ? 1 : -1;

  return [...displayingProjects].sort((a, b) => {
    const mainField = objectMapping(a, sortField);
    const secondaryField = objectMapping(b, sortField);

    if (mainField === null || mainField === undefined) return sortingNominator;
    if (secondaryField === null || secondaryField === undefined) return -sortingNominator;

    if (mainField > secondaryField) return -sortingNominator;
    if (mainField < secondaryField) return sortingNominator;

    return compareNames(a, b);
  });
};

const sortDates = (sortOrder, displayingProjects, sortField) => {
  const sortingNominator = sortOrder === "a" ? 1 : -1;

  return [...displayingProjects].sort((a, b) => {
    const mainField = objectMapping(a, sortField);
    const secondaryField = objectMapping(b, sortField);

    if (!mainField) return sortingNominator;
    if (!secondaryField) return -sortingNominator;

    const dateComparison = new Date(mainField).getTime() - new Date(secondaryField).getTime();
    if (dateComparison !== 0) return sortingNominator * (dateComparison > 0 ? 1 : -1);

    return compareNames(a, b);
  });
};
